import Pusher from 'pusher-js';
import Echo from 'laravel-echo';

export default defineNuxtPlugin(() => {
    window.Pusher = Pusher;
    const runtimeConfig = useRuntimeConfig();
    const { usableToken } = useAuthStore();
    const websocket = new Echo({
        broadcaster: 'pusher',
        authEndpoint: `${runtimeConfig.public.worksiteApiUrl}/broadcasting/auth`,
        auth: {
            headers: {
                Authorization: usableToken,
            },
        },
        key: '$2y$10$svQIJ8lZEqclI9AqcZEBZeKEqhePKWoFTFU85ML2u9w4aa2wbRHG',
        wsHost: 'websocket.trustup.io',
        wsPort: 443,
        wssPort: 443,
        disableStats: true,
    });

    return {
        provide: {
            websocket,
        },
    };
});
