let sidebarBase =
    'fixed border-t py-2 md:py-0 border-gray-200 md:border-t-0 bottom-0 left-0 right-0 md:relative md:bottom-auto md:left-auto md:right-auto flex md:flex-col flex-shrink-0 bg-white dark:bg-neutral-800 w-full md:w-[80px] dark:border-l border-r border-gray-200 dark:border-gray-900 transition-all dark:border-gray-900 z-[9] overflow-y-auto nice-scrollbar';
let sidebarLinkBase =
    'flex items-center justify-center w-full p-2 text-gray-800 transition-all rounded hover:bg-neutral-100 active:bg-neutral-200 dark:hover:bg-neutral-600 dark:hover:text-gray-300 dark:text-gray-200 hover:text-primary group/sidebar-link whitespace-nowrap truncate';

let layout = {
    sidebar: `${sidebarBase} lg:w-[240px]`,
    sidebar_collapsed: sidebarBase,
    sidebar_links_container: 'v-spa-layout-sidebar flex items-center w-auto h-full space-x-6 md:w-full md:items-start md:flex-col md:space-x-0 md:space-y-2',
    sidebar_links_separator: 'mb-2 mt-2 h-[1px] w-full bg-neutral-200 dark:bg-neutral-900 hidden md:block',
    sidebar_link_container: 'w-auto md:px-3 md:mt-4 md:w-full',
    sidebar_link: `${sidebarLinkBase} lg:justify-start space-x-2`,
    sidebar_link_collapsed: sidebarLinkBase,
    sidebar_link_active: 'text-black bg-neutral-100 dark:bg-blue-gray-900 dark:text-white',
    sidebar_link_icon: 'fa-light w-auto md:w-[20px] text-gray-600 dark:text-gray-300 text-xl md:text-lg group-hover/sidebar-link:text-primary transition-all',
    sidebar_link_text: 'hidden w-full text-sm lg:block',
    page_tabs: {
        container: 'flex space-x-8',
        tab: 'pb-2 text-gray-600 whitespace-nowrap transition-all hover:text-gray-800 v-spa-layout-page-tab',
        tab_active: 'v-spa-layout-page-tab-active',
    },
};

export default layout;
