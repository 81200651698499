export default defineNuxtPlugin(() => {
    return {
        provide: {
            truncate: (value: string | null, length: number = 100) => {
                if (value == null) {
                    return '';
                }
                return value.length > length ? value.substring(0, length) + '...' : value;
            },
        },
    };
});
