export default defineNuxtPlugin((_nuxtApp) => {
    const {
        public: { appEnv },
    } = useRuntimeConfig();

    if (appEnv === 'local') {
        return;
    }
    if (import.meta.hot) {
        import.meta.hot.on('vite:afterUpdate', () => {
            // eslint-disable-next-line no-console
            console.clear();
        });
    }
});
