// window.addEventListener('error', function (event) {
//     console.log('An error was catched.', event);
// });

export default defineNuxtPlugin(() => {
    return {
        provide: {
            trackEvent: (event: string, data?: any) => {
                // TODO reactivate this feature with mix panel?
                // if (!window.tracking_group_id) {
                //     window.tracking_group_id = uuidv4();
                // }
                // const { authed, tenant } = storeToRefs(useAuthStore());
                // data = data || {};
                // data.url = window.location.href;
                // fetch('https://tracking.trustup.io/api/v1/events', {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/vnd.api+json',
                //         Accept: 'application/vnd.api+json',
                //     },
                //     body: JSON.stringify({
                //         data: {
                //             type: 'events',
                //             attributes: {
                //                 appName: 'worksite',
                //                 event,
                //                 data,
                //                 group: window.tracking_group_id,
                //                 userId: authed.value?.getId(),
                //                 accountId: tenant.value?.getId(),
                //             },
                //         },
                //     }),
                // });
            },
        },
    };
});
