<template>
    <div>
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>

<script lang="ts" setup>
    // const app = useNuxtApp();

    // Stop pinging tracking app.
    // setInterval(() => {
    //     app?.$trackEvent('ping');
    // }, 1000 * 60);

    useHead({
        script: [
            {
                innerHTML: `window.intercomSettings = {
                    api_base: "https://api-iam.intercom.io",
                    app_id: "tlrr93he",
                    hide_default_launcher: true
                };`,
            },
            {
                innerHTML:
                    "(function(){var w=window;var ic=w.Intercom;if(typeof ic===\"function\"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/tlrr93he';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();",
            },
        ],
    });

    onMounted(() => {
        // window.Intercom('boot', {
        //     app_id: 'tlrr93he',
        //     custom_launcher_selector: '#my_custom_link'
        // });
    });
</script>
