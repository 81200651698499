import User from '~~/app/auth/models/User';
import Tenant from '~~/app/auth/models/Tenant';
import TenantCollection from '~/app/auth/collections/TenantCollection';

interface AuthStoreState {
    token: Ref<string | null>;
    impersonateToken: Ref<string | null>;
    usableToken: Ref<string | null>;
    isImpersonating: Ref<boolean>;
    authed: Ref<User | null>;
    tenant: Ref<Tenant | null>;
    tenants: Ref<TenantCollection | null>;
    logout: () => void;
    quitImpersonation: () => void;
    setImpersonator: (user: User, userToken: string) => void;
    setUser: (user: User, userToken: string) => void;
    setUserModel: (user: User) => void;
    setTenant: (tenant: Tenant) => void;
    // setMeiliSearchToken: (token: string) => void;
    resetTenant: () => void;
    setTenants: (tenants: TenantCollection) => void;
    resetTenants: () => void;
    isImpersonated: Ref<boolean>;
}

export const useAuthStore = defineStore('AuthStore', () => {
    const token: Ref<string | null> = ref(localStorage.getItem('token'));

    const impersonateToken: Ref<string | null> = ref(localStorage.getItem('impersonateToken'));
    const isImpersonated = computed(() => {
        return impersonateToken.value !== null && impersonateToken.value !== '';
    });
    // const token: Ref<string | null> = ref('Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiZTFhYjI4ODM5ZDMwZDcyZGJjYzVhZWFmNjFkYjIzYmIyNzQ0MDUzOGMyMWQ4ZDM0NmU4OTEyMjIzMjBkZTdjYmY3MWFkYzZlZGUwYWQ1MzEiLCJpYXQiOjE2NzY1MzgxMjUuNTU2ODgyLCJuYmYiOjE2NzY1MzgxMjUuNTU2ODg1LCJleHAiOjE3MDgwNzQxMjUuNTI2NjY0LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.JMpOqdnpgXDayexqr8vY_PoNuZ5yD8XXHEvSoJ7n8LcSrsqB67Q1yJBOvo93j8FP31V1xc8F5Qh0nVwFb1kqQErKntssruSeSocReapexkVq_BHOSyCe_0Vd6ytsREtw2nIPCFaTE_Wv-0_j80y3H6MIGgHKuLVsv9dZ46mVUOgjXZhThtFBeuBaIZryvJ8fpqojlGuZ0RZWsCdnVzVRKLWkTqCubzYFAjpz2DInflKmLOGIZhjJwJ0ivWsLTtjce31Jvhi_58FASQFAWB7Y3FuyHlYPS0Jvwl15DXheT0G40_Jl-OqzG9L_3LwDixUxDiKweVKm1hURYwCsvft0TVGMizVW_HBYM18zUvjDHT35mijpQ5UZqU-YL8xxqkHxL5bzIF5cjwiW0DW8cHQDInDDe4KkBqdAlfEWGRsaet0dU2sNwNpjL_U2SQKyxcOp58qRDm397gX7-SaZXB9wxQWiRayKIZTdMRMCD763W9MS2SS2PnqJbpa-tzWujftlq95yxEJ3igydcLuyOEqGJrwJdrC_3hgyN9D9sG86ARWZTUtk3LZA7WtERttCVTfGEnBFmxND-bVsx6_5l2JpE6bzb_qopWk27iFngaizYL2kbKygh97k7Qy3XJ0LRPtoJVGl_bsad83E-FKqCLJpcVp1oSSFYNyQVRQvAffV4k8');
    const authed: Ref<User | null> = ref(null);
    const tenant: Ref<Tenant | null> = ref(null);
    const tenants: Ref<TenantCollection | null> = ref(null);

    const usableToken = computed(() => {
        return impersonateToken.value || token.value;
    });

    const quitImpersonation = () => {
        localStorage.removeItem('impersonateToken');
        impersonateToken.value = null;
        authed.value = null;
        tenant.value = null;
    };

    const logout = () => {
        localStorage.removeItem('impersonateToken');
        localStorage.removeItem('token');
        token.value = null;
        impersonateToken.value = null;
        authed.value = null;
        tenant.value = null;
    };

    const setImpersonator = (user: User, userToken: string) => {
        localStorage.setItem('impersonateToken', userToken);
        impersonateToken.value = userToken;
        authed.value = user;
    };

    const setUser = (user: User, userToken: string) => {
        localStorage.removeItem('impersonateToken');
        localStorage.setItem('token', userToken);
        token.value = userToken;
        setUserModel(user);
    };

    const setUserModel = (user: User) => {
        authed.value = user;

        useIntercomUpdate({
            user_id: user.getId(),
            user_first_name: user.firstName,
            user_last_name: user.lastName,
            user_hash: user.intercomHash,
            email: user.email,
            phone: user.phone,
            language_override: user.language,
        });

        // window.openreplay?.setUserID(user.getId());
        // window.openreplay?.setMetadata('user_email', user.email);
    };

    const setTenant = (model: Tenant) => {
        tenant.value = model;
        if (authed.value && model.authedMeta) {
            authed.value.setTenantData(model.authedMeta);
        }

        useIntercomUpdate({
            trustup_pro_manager_tenant_id: model.getId(),
            trustup_pro_manager_tenant_name: model.name,
            trustup_pro_manager_tenant_subscription_status: model.subscriptionStatus,
            trustup_pro_manager_tenant_subscription_plan: model.subscriptionPlan,
            trustup_pro_manager_tenant_subscription_billing_cycle: model.subscriptionBillingCycle,
            trustup_pro_manager_tenant_from_v1: model.fromV1,
            trustup_pro_manager_tenant_last_invoice_at: model.lastInvoice?.createdAt.unix() || null,
            trustup_pro_manager_tenant_last_invoice_id: model.lastInvoice?.id || null,
            trustup_pro_manager_tenant_created_at: model.createdAt?.unix() || null,
            trustup_pro_manager_tenant_activated_at: model.activatedAt?.unix() || null,
            trustup_pro_manager_tenant_internal_status: model.internalStatus,
            trustup_pro_manager_tenant_last_satisfaction_at: model.satisfaction?.date.unix() || null,
            trustup_pro_manager_tenant_last_satisfaction_value: model.satisfaction?.value || null,
        });

        // window.openreplay?.setMetadata('tenant', model.getId());
        // window.openreplay?.setMetadata('tenant_name', model.name);
        // window.openreplay?.setMetadata('tenant_subscription_status', model.subscriptionStatus);
        // window.openreplay?.setMetadata('tenant_subscription_plan', model.subscriptionPlan);
        // window.openreplay?.setMetadata('tenant_subscription_billing_cycle', model.subscriptionBillingCycle);
    };

    const resetTenant = () => {
        tenant.value = null;
        authed.value?.resetTenantData();
    };

    const setTenants = (collection: TenantCollection) => {
        tenants.value = collection;
    };

    // const setMeiliSearchToken = (token: string) => {
    //   meiliSearchToken.value = token;
    // };

    const resetTenants = () => {
        tenants.value = null;
    };

    const isImpersonating = computed(() => {
        return !!impersonateToken.value;
    });

    useListen('trustup:user:updated', (updated: User) => {
        if (authed.value?.getId() === updated.getId()) {
            authed.value.refresh(updated);
        }

        for (let i = 0; i < tenant.users?.items.length; i++) {
            if (tenant.users?.items[i].getId() === updated.getId()) {
                tenant.users.items[i].refresh(updated);
            }
        }
    });

    useListen('tenancy:tenant:updated', (updated: Tenant) => {
        if (tenant.value?.getId() === updated.getId()) {
            setTenant(updated);
        }

        for (let i = 0; i < tenants.value?.items.length; i++) {
            if (tenants.value?.items[i].getId() === updated.getId()) {
                tenants.value.items[i] = updated;
            }
        }
    });

    const state: AuthStoreState = {
        token,
        impersonateToken,
        isImpersonated,
        usableToken,
        isImpersonating,
        authed,
        tenant,
        tenants,
        logout,
        quitImpersonation,
        setImpersonator,
        setUser,
        setUserModel,
        setTenant,
        setTenants,
        resetTenant,
        resetTenants,
    };

    return state;
});
