const submenu = {
    container: {
        withoutPosition: 'absolute z-[100] shadow-xl bg-white dark:bg-black whitespace-nowrap border border-gray-100 dark:border-black rounded-lg outline-none',
    },
    overlay: {
        base: 'fixed z-[99] inset-0',
        opacity: 'opacity-10',
    },
    items_container: 'flex flex-col divide-y divide-gray-100 dark:divide-gray-900',
    items: 'flex flex-col space-y-1 py-2',
    item_without_icon:
        'text-sm whitespace-nowrap block px-4 py-2 rounded hover:bg-neutral-100 active:bg-neutral-200 dark:hover:bg-blue-900 text-gray-700 dark:text-gray-400 hover:text-primary dark:hover:text-gray-200 transition-all group/submenuitem',
    item_icon: 'text-gray-500 group-hover/submenuitem:text-primary dark:group-hover/submenuitem:text-gray-200 transition-all w-[15px]',
    transition: {
        'enter-active-class': 'duration-300 transition-overshoot',
        'enter-from-class': '-translate-y-[50px] scale-90 opacity-0',
        'enter-to-class': 'translate-y-0 scale-100 opacity-100',
        'leave-active-class': 'duration-200 ease-in',
        'leave-from-class': 'translate-y-0 scale-100 opacity-100 blur-none',
        'leave-to-class': '-translate-y-[50px] scale-90 opacity-0 blur-md',
    },
};

submenu.item = `${submenu.item_without_icon} flex items-center space-x-2`;
submenu.container.base = `${submenu.container.withoutPosition}`;
submenu.container.default = `${submenu.container.base} px-2`;
submenu.container.large = `${submenu.container.base} px-4 py-2`;
submenu.container.xl = `${submenu.container.base} p-4`;
submenu.container.xxl = `${submenu.container.base} p-6`;

export default submenu;
