const tabs = {
    link: 'text-xs md:text-sm lg:text-base text-gray-700 mx-3 pb-1',
    active: 'text-xs md:text-sm lg:text-base text-primary border-b-2 border-primary mx-3 pb-1',
    sidebar: {
        title: 'uppercase mb-2 block text-sm',
        link: 'text-gray-700 ml-3 text-xs',
        active: 'text-primary ml-3 text-xs',
    },
};

export default tabs;
