const input = 'w-full text-black transition-all placeholder-gray-500 font-thin rounded border border-gray-200 dark:border-gray-900 bg-white outline-none hover:border-gray-600 focus:border-primary';
const label = 'text-gray-400 block mb-1 cursor-pointer';
const select =
    'w-full pl-3 py-2 pr-[2px] select-with-chevron hover:bg-neutral-100 cursor-pointer rounded outline-none border border-gray-200 dark:border-gray-900 text-black hover:border-gray-600 focus:border-primary';
const inputBase = `${input} px-3 py-2`;

const form = {
    select_full_width: select + ' text-sm',
    select_full_width_xs: select + ' text-xs',
    select: select + ' max-w-input text-sm',
    select_xs: select + ' max-w-input text-xs h-[34px]',
    input_full_width: `${inputBase} text-sm`,
    input_full_width_xs: `${inputBase} text-xs`,
    input: `${inputBase} max-w-input text-sm`,
    input_disabled: `${inputBase
        .replace('placeholder-gray-500', 'placeholder-gray-300')
        .replace('hover:border-gray-600', '')
        .replace('text-black', 'text-gray-600')
        .replace('bg-white', 'bg-neutral-50')} max-w-input text-sm`,
    input_xs: `${inputBase} max-w-input text-xs`,
    input_xs_disabled: `${inputBase
        .replace('placeholder-gray-500', 'placeholder-gray-300')
        .replace('hover:border-gray-600', '')
        .replace('text-black', 'text-gray-600')
        .replace('bg-white', 'bg-neutral-50')} max-w-input text-xs`,
    input_large: `${input} px-4 py-3 text-sm`,
    textarea: 'w-full text-black placeholder-gray-500 font-thin px-3 py-2 rounded border border-gray-300 bg-white outline-none hover:border-gray-600 focus:border-primary text-sm min-h-[120px]',
    textarea_notes: 'text-sm text-gray-800 min-h-[140px] bg-[#f9f8d1] px-3 py-2 rounded w-full h-[200px]',
    label_inline: label + ' text-sm inline-block',
    label: label + ' text-sm',
    label_xs: label + ' text-xs',
    horizontal_label: 'text-gray-500 font-normal text-sm cursor-pointer',
    helper: 'block text-gray-500 font-thin text-sm',
    containers: {
        element: 'mb-6 flex flex-wrap',
        input: 'w-full md:flex-auto',
        label: 'w-full',
        submit: 'flex justify-stretch w-full space-x-2',
    },
    filters: {
        search: {
            input: 'w-full py-2 px-3 md:py-4 md:px-6 rounded-lg bg-white border border-gray-200 dark:border-gray-900 hover:shadow focus:shadow-lg text-gray-700 text-xs md:text-sm lg:text-base font-thin outline-none focus:border-primary',
            icon: 'fas fa-search outline-none text-gray-800 absolute center-y right-0 mr-4 md:mr-8 text-xs md:text-sm lg:text-base',
        },
        select: {
            input: 'w-full py-2 px-3 md:py-4 md:px-6 pr-8 md:pr-10 rounded-lg bg-white border border-gray-200 dark:border-gray-900 hover:shadow focus:shadow-lg text-gray-500 text-xs md:text-sm lg:text-base font-thin outline-none focus:border-primary',
            icon: 'fas fa-arrow-down text-gray-800 absolute center-y right-0 mr-4 md:mr-6 text-sm',
        },
    },
};

export default form;
