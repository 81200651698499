export const useSetupStore = defineStore('SetupStore', () => {
    const completed: Ref<boolean> = ref(false);
    const markAsCompleted = () => {
        completed.value = true;
    };
    const reset = () => {
        completed.value = false;
    };

    return {
        completed,
        reset,
        markAsCompleted,
    };
});
