/**
 * Track the navigation in the application
 */
export default defineNuxtRouteMiddleware((to, from) => {
    const app = useNuxtApp();

    if (from.path === to.path) {
        return;
    }

    app?.$trackEvent('navigation', {
        from: from.path,
        to: to.path,
    });

    useIntercomUpdate({
        last_request_at: parseInt(new Date().getTime() / 1000),
    });
});
