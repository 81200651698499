import VueGoogleMaps from 'vue-google-maps-community-fork';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyDhSsZYZLkPQ95xIbelqhmvnU7GZEn7tG8',
            language: 'en',
            libraries: 'places',
        },
    });
});
