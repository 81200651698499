export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('can', (el, binding, _vnode) => {
        const { authed } = useAuthStore();

        if (!authed || !binding.value) {
            return el;
        }
        return authed?.hasPermission(binding.value) ? el : el.parentNode?.removeChild(el);
    });
    nuxtApp.vueApp.directive('role', (el, binding, _vnode) => {
        const { authed } = useAuthStore();

        if (!authed) {
            return el;
        }

        return authed?.role === binding.value ? el : el?.parentNode?.removeChild(el);
    });
});
