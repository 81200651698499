/**
 * Close the search modal whenever a navigation occurs.
 */
export default defineNuxtRouteMiddleware((to, from) => {
    if (from.path === to.path) {
        return;
    }

    useEvent('app:search:close');
});
