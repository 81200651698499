const table = {
    table_container: 'overflow-x-auto nice-scrollbar whitespace-nowrap',
    table: 'text-left w-full border-collapse',
    th_text: 'text-xs text-gray-500 uppercase font-normal',
    td_text: 'text-gray-800',
    tr: 'transition-all hover:bg-neutral-50 active:bg-neutral-100 group table-tr border-t first:border-t-0 border-gray-200 hover:border-white',
    tr_first: 'border-t-0',
};

table.th = `${table.th_text} py-1 px-2 lg:py-2 lg:px-4 bg-neutral-100 text-sm font-medium text-left whitespace-nowrap`;
table.th_first = `rounded-l-lg`;
table.th_last = `!text-right rounded-r-lg`;
table.td = `${table.td_text} py-2 px-2 lg:py-4 lg:px-4 text-sm cursor-pointer leading-tight`;
table.td_sm = `${table.td_text} py-1 px-2 lg:py-2 lg:px-4 text-sm cursor-pointer`;
table.td_first = `rounded-l-lg`;
table.td_last = `text-right rounded-r-lg`;

export default table;
