/**
 * Wait for the app to setup before handling page navigation.
 */
export default defineNuxtRouteMiddleware((to, from) => {
    const { completed } = useSetupStore();
    if (!completed) {
        return;
    }

    const redirect = useGetGlobalRedirection(to);
    if (redirect) {
        console.log('Found redirect in middleware', redirect);
        return navigateTo(redirect);
    }
});
