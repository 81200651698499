import { flare } from '@flareapp/flare-client';
import { flareVue } from '@flareapp/flare-vue';

export default defineNuxtPlugin(({ $config, vueApp }) => {
    /**
     * Reactivate this code to enable Flare error tracking
     * when needed.
     */
    return;

    // if (process.env.NODE_ENV === 'production') {
    //     flare.light($config.public.flareKey);
    // }
    // flare.beforeSubmit = (report) => {
    //     return {
    //         ...report,
    //         application_path: report.context.request?.url,
    //     };
    // };
    // vueApp.use(flareVue);
});
