/**
 * Ensure that the correct module is set in the store based on the route.
 */
export default defineNuxtRouteMiddleware((to, from) => {
    const { currentModule, modules, setCurrentModule, unsetCurrentModule } = useModules();

    const prefix = to.path.split('/')[1];
    if (prefix) {
        if (currentModule.value?.key === prefix) {
            updateMetaTitle(currentModule.value.key);
            return;
        }

        const module = modules.value.find((module) => module.key === prefix);
        if (module) {
            setCurrentModule(module.key, false);
            updateMetaTitle(module.key);
            return;
        }

        /**
         * The bankaccount module is currently only a link inside the invoicing module,
         * but the url prefix is already reserved for future use.
         */
        if (prefix === 'bankaccount') {
            setCurrentModule('invoicing', false);
            updateMetaTitle('invoicing');
            return;
        }
    }

    unsetCurrentModule();
});

function updateMetaTitle(moduleKey: string) {
    const { t } = useTranslation();
    if (t('modules.' + moduleKey) !== 'modules.' + moduleKey) {
        useSeoMeta({
            title: t(`modules.${moduleKey}`) + ' - TrustUp Pro Manager',
        });
    }
}
