import validate from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import close_45search_45modal_45global from "/opt/buildhome/repo/middleware/close-search-modal.global.ts";
import module_45global from "/opt/buildhome/repo/middleware/module.global.ts";
import setup_45global from "/opt/buildhome/repo/middleware/setup.global.ts";
import track_45navigation_45global from "/opt/buildhome/repo/middleware/track-navigation.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  close_45search_45modal_45global,
  module_45global,
  setup_45global,
  track_45navigation_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}