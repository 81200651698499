import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import translationsPlugin_qSxnnlDu2e from "/opt/buildhome/repo/node_modules/@deegital/nuxt-trustup-io-translations/dist/runtime/plugins/translationsPlugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import clear_console_PeKWvKLXBD from "/opt/buildhome/repo/plugins/clear-console.ts";
import contextmenu_client_PPZuVeGvUI from "/opt/buildhome/repo/plugins/contextmenu.client.ts";
import flare_client_8OewLYGJqN from "/opt/buildhome/repo/plugins/flare.client.ts";
import konami_code_client_Ex8K6GIUoo from "/opt/buildhome/repo/plugins/konami-code.client.ts";
import mixPanel_1AMjhXMggU from "/opt/buildhome/repo/plugins/mixPanel.ts";
import moment_NWWiRYbNqj from "/opt/buildhome/repo/plugins/moment.ts";
import openreplay_client_x9gNryAGwo from "/opt/buildhome/repo/plugins/openreplay.client.ts";
import permissions_MHRAHWKe7t from "/opt/buildhome/repo/plugins/permissions.ts";
import price_8YPXKugP1w from "/opt/buildhome/repo/plugins/price.ts";
import theme_zupea9J243 from "/opt/buildhome/repo/plugins/theme.ts";
import truncate_rSkBTNPyXO from "/opt/buildhome/repo/plugins/truncate.ts";
import vue_google_maps_client_gpNQwFOD8U from "/opt/buildhome/repo/plugins/vue-google-maps.client.ts";
import vue_safe_teleport_client_3tq5y36iTE from "/opt/buildhome/repo/plugins/vue-safe-teleport.client.ts";
import vue_tel_input_client_Cyn6ok7DxA from "/opt/buildhome/repo/plugins/vue-tel-input.client.ts";
import vue_trustup_event_tracking_ruaxAUoCqp from "/opt/buildhome/repo/plugins/vue-trustup-event-tracking.ts";
import websocket_E4fTueNpBJ from "/opt/buildhome/repo/plugins/websocket.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  translationsPlugin_qSxnnlDu2e,
  chunk_reload_client_UciE0i6zes,
  clear_console_PeKWvKLXBD,
  contextmenu_client_PPZuVeGvUI,
  flare_client_8OewLYGJqN,
  konami_code_client_Ex8K6GIUoo,
  mixPanel_1AMjhXMggU,
  moment_NWWiRYbNqj,
  openreplay_client_x9gNryAGwo,
  permissions_MHRAHWKe7t,
  price_8YPXKugP1w,
  theme_zupea9J243,
  truncate_rSkBTNPyXO,
  vue_google_maps_client_gpNQwFOD8U,
  vue_safe_teleport_client_3tq5y36iTE,
  vue_tel_input_client_Cyn6ok7DxA,
  vue_trustup_event_tracking_ruaxAUoCqp,
  websocket_E4fTueNpBJ
]