import Moment from 'moment';
import 'moment/dist/locale/de';
import 'moment/dist/locale/nl';
import 'moment/dist/locale/fr';
import { extendMoment } from 'moment-range';
import momentDurationFormatSetup from 'moment-duration-format';

const moment = extendMoment(Moment);
momentDurationFormatSetup(moment);

declare module 'moment' {
    interface Moment {
        localized(): Moment;
        localizedFormat(format: string): string;
        toDayDateString(): string;
        toEUDateString(): string;
        toUtcDateTimeString(): string;
        toShortDayDateString(): string;
        toDateString(): string;
        toDateTimeString(): string;
        toTimeString(separator?: string): string;
        toShortTimeString(separator?: string): string;
        toTimepickerObject(): { hours: number; minutes: number; seconds: number };
        rangeToArray(): [];
    }

    interface Duration {
        toTimeString(separator?: string): string;
        toShortTimeString(separator?: string): string;
    }
}

(moment as any).fn.localized = function (): string {
    return this;
};

(moment as any).fn.localizedFormat = function (format: string): string {
    return this.format(format);
};

(moment as any).fn.toUtcDateTimeString = function (): string {
    return this.utc().format('YYYY-MM-DD HH:mm:ss');
};

(moment as any).fn.toDayDateString = function (): string {
    return this.format('dddd, DD MMMM YYYY');
};

(moment as any).fn.toShortDayDateString = function (): string {
    return this.format('DD MMMM YYYY');
};

(moment as any).fn.toEUDateString = function (): string {
    return this.format('DD/MM/YYYY');
};

(moment as any).fn.toDateString = function (): string {
    return this.format('YYYY-MM-DD');
};

(moment as any).fn.toDateTimeString = function (): string {
    return this.format('YYYY-MM-DD HH:mm:ss');
};

(moment as any).fn.toTimeString = function (separator = ':'): string {
    return this.format(`HH[${separator}]mm[${separator}]ss`);
};

(moment as any).fn.toShortTimeString = function (separator = ':'): string {
    return this.format(`HH[${separator}]mm`);
};

(moment as any).duration.fn.toTimeString = function (separator = ':'): string {
    return this.format(`hh[${separator}]mm[${separator}]ss`, { trim: false });
};

(moment as any).duration.fn.toShortTimeString = function (separator = ':'): string {
    return this.format(`hh[${separator}]mm`, {
        trim: false,
    });
};

(moment as any).fn.toShortDayDateTimeString = function (): string {
    const translation = useTranslation();
    const date = this.format('DD MMMM YYYY');
    const time = this.format('HH:mm');
    return translation.t('misc.datetime', { date, time });
};

(moment as any).fn.receivedAt = function (): string {
    const translation = useTranslation();
    const date = this.format('dddd DD MMMM YYYY');
    const time = this.format('HH:mm');
    return translation.t('misc.received_at', { date, time });
};

(moment as any).fn.toTimepickerObject = function (): {
    hours: number;
    minutes: number;
    seconds: number;
} {
    return {
        hours: this.hours(),
        minutes: this.minutes(),
        seconds: this.seconds(),
    };
};

window.moment = moment;

export default moment;
