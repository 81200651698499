import table from './theme/table';
import button from './theme/button';
import form from './theme/form';
import tabs from './theme/tabs';
import modal from './theme/modal';
import submenu from './theme/submenu';
import layout from './theme/layout';
import contextmenu from './theme/contextmenu';

let css: Object = {
    table,
    button,
    form,
    tabs,
    modal,
    submenu,
    contextmenu,
    layout,
};
css = flattenObject(css);

console.log('💄 Theme available.', css);

function flattenObject(object: Object) {
    var toReturn = {};

    for (var i in object) {
        if (!object.hasOwnProperty(i)) continue;

        if (typeof object[i] == 'object' && object[i] !== null) {
            var flatObject = flattenObject(object[i]);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;

                toReturn[i + '.' + x] = flatObject[x];
            }
        } else {
            toReturn[i] = object[i];
        }
    }
    return toReturn;
}

export default defineNuxtPlugin(() => {
    return {
        provide: {
            theme: (key: string, extraClasses: string = '', replace: Object = {}) => {
                if (typeof css[key] == 'undefined') {
                    console.warn('Theme class not found: ' + key);
                    return '';
                }

                let classes: string = css[key] + ' ' + extraClasses;

                for (let key in replace) {
                    classes = classes.replace(key, replace[key]);
                }

                return classes;
            },
        },
    };
});
