import Decimal from 'decimal.js';
import { storeToRefs } from 'pinia';

export default defineNuxtPlugin(() => {
    const { authed } = storeToRefs(useAuthStore());
    return {
        provide: {
            rounded: (value: number) => Math.round(value),
            rounded2: (value: number) => Math.round(value * 100) / 100,
            priceFromEuros: (euros: number) => euros.toLocaleString(authed.value?.priceLocale || 'fr-BE', { style: 'currency', currency: authed.value?.priceCurrency || 'EUR' }),
            price: (cents: number) => (cents / 100).toLocaleString(authed.value?.priceLocale || 'fr-BE', { style: 'currency', currency: authed.value?.priceCurrency || 'EUR' }),
            priceWithPartialCents: (cents: number | null) => {
                const decimal = new Decimal(cents || 0);
                const euros = decimal.times(10).round().div(1000).toNumber();

                return euros.toLocaleString(authed.value?.priceLocale || 'fr-BE', {
                    style: 'currency',
                    currency: authed.value?.priceCurrency || 'EUR',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 3,
                });
            },
        },
    };
});
