import mixpanel from 'mixpanel-browser';

export default defineNuxtPlugin((nuxtApp) => {
    const {
        public: { mixpanelToken, appEnv },
    } = useRuntimeConfig();

    // eslint-disable-next-line import/no-named-as-default-member
    mixpanel.init(mixpanelToken, { debug: true });

    const isLocal = appEnv === 'local';
    if (isLocal) {
        // eslint-disable-next-line import/no-named-as-default-member
        mixpanel.disable();
    }

    nuxtApp.vueApp.use({
        install(app) {
            app.config.globalProperties.$mixpanel = mixpanel;
        },
    });

    return {
        provide: {
            mixpanel,
        },
    };
});
