export interface ModuleInterface {
    key: string;
    defaultPath: Function;
    icon: string;
    color: string;
    sidebar?: string;
    current: boolean;
    checkPermission: boolean;
    permissionKey?: string;
}

export default function useModules() {
    const modules = useState('modules', () => [
        {
            key: 'dashboard',
            defaultPath: () => '',
            icon: 'gauge-high',
            color: '#32A3FF',
            sidebar: 'apps',
            current: true,
            checkPermission: true,
            permissionKey: 'app:dashboard',
        },
        {
            key: 'invoicing',
            defaultPath: () => 'statistics',
            icon: 'file-invoice',
            color: '#3498db',
            sidebar: 'apps',
            current: false,
            checkPermission: true,
        },
        {
            key: 'calendar',
            defaultPath: () => '',
            icon: 'calendar-day',
            color: '#8518C8',
            sidebar: 'apps',
            current: false,
            checkPermission: true,
            permissionKey: 'planning:access',
        },
        {
            key: 'planning',
            defaultPath: () => '',
            icon: 'chart-simple-horizontal',
            color: '#8518C9',
            sidebar: 'apps',
            current: false,
            checkPermission: true,
            permissionKey: 'planning:access',
        },
        {
            key: 'worksite',
            defaultPath: () => 'worksites',
            icon: 'helmet-safety',
            color: '#10A4D3',
            sidebar: 'apps',
            current: false,
            checkPermission: true,
        },
        {
            key: 'task',
            defaultPath: () => '',
            icon: 'check-circle',
            color: '#8518D9',
            sidebar: 'apps',
            current: false,
            checkPermission: true,
        },
        {
            key: 'timesheet',
            defaultPath: () => '',
            icon: 'timer',
            color: '#8518D8',
            sidebar: 'apps',
            current: false,
            checkPermission: true,
            permissionKey: 'planning:access',
        },
        {
            key: 'crm',
            defaultPath: () => 'leads',
            icon: 'bullseye-arrow',
            color: '#00b894',
            sidebar: 'apps',
            current: false,
            checkPermission: true,
        },
        // {
        //     'key': 'interventions',
        //     'icon': 'wrench',
        //     'color': '#EE6D36',
        //     'current': false
        // },
        // {
        //     'key': 'statistics',
        //     'icon': 'chart-gantt',
        //     'color': '#000000',
        //     'current': false
        // },
        {
            key: 'settings',
            icon: 'cog',
            color: '#EA7133',
            defaultPath: () => 'company',
            sidebar: 'extra',
            current: false,
            checkPermission: true,
        },
    ]);

    const currentModule = computed(() => {
        return modules.value.find((module) => module.current);
    });

    const unsetCurrentModule = () => {
        modules.value.forEach((module) => {
            module.current = false;
        });
    };

    const setCurrentModule = (key: string, redirect = true) => {
        modules.value.forEach((module) => {
            module.current = module.key === key;
        });

        if (redirect && currentModule.value) {
            return navigateTo(`/${currentModule.value.key}/${currentModule.value.defaultPath()}`);
        }
    };

    const appsModules = computed(() => {
        return modules.value.filter((module) => module.sidebar === 'apps');
    });
    const extraModules = computed(() => {
        return modules.value.filter((module) => module.sidebar === 'extra');
    });

    return {
        modules,
        appsModules,
        extraModules,
        currentModule,
        setCurrentModule,
        unsetCurrentModule,
    };
}
