export default function useIntercomUpdate(data: Record<string, any>) {
    const {
        public: { appEnv },
    } = useRuntimeConfig();

    if (appEnv === 'local') {
        return;
    }

    if (!window.Intercom) {
        setTimeout(() => useIntercomUpdate(data), 5000);
        return;
    }

    window.Intercom('update', data);
}
