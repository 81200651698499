const modal = {
    overlay: 'absolute inset-0',
    title: {
        container: 'p-4 pt-6 md:px-6',
        text: 'text-xl font-thin text-center text-gray-800',
        subtext: 'text-sm text-center text-gray-600',
    },
    titleLeft: {
        container: 'p-4 pt-6 md:px-6',
        text: 'text-xl font-thin text-left text-gray-800',
        subtext: 'text-sm text-left text-gray-600',
    },
    footer: {
        container: 'px-4 pb-4 md:px-6',
        button_container: 'flex justify-between space-x-4',
        button: 'w-full',
    },
    footerFitCentered: {
        container: 'px-4 pb-4 md:px-6',
        button_container: 'flex justify-center space-x-4',
        button: 'w-fit',
    },
    closeIcon: {
        container: 'absolute top-0 right-0 z-10 pt-6 pr-4 flex items-center justify-center',
        button: 'relative w-8 h-8 text-gray-400 transition-all rounded bg-white border border-gray-100 hover:bg-neutral-100 hover:text-gray-700',
    },
    padding: 'p-4 md:p-6',
    centered: {
        container: {
            outer: 'z-[900] fixed top-0 right-0 bottom-0 left-0 z-50 flex justify-stretch sm:justify-center items-stretch sm:items-center p-4',
            inner: 'relative z-10 w-full bg-white shadow rounded-lg xl:rounded-xl min-h-[200px] outline-none min-w-[100%] sm:min-w-0',
        },
        content: 'relative',
        transition: {
            'enter-active-class': 'duration-300 transition-overshoot',
            'enter-from-class': 'translate-y-[50px] sm:translate-y-0 sm:scale-90 opacity-0',
            'enter-to-class': 'translate-y-0 sm:scale-100 opacity-100',
            'leave-active-class': 'duration-200 ease-in',
            'leave-from-class': 'translate-y-0 sm:scale-100 opacity-100 blur-none',
            'leave-to-class': 'translate-y-[50px] sm:translate-y-0 sm:scale-90 opacity-0 blur-md',
        },
    },
    right_panel: {
        container: {
            outer: 'z-[900] fixed top-0 right-0 bottom-0 left-0 z-50 flex justify-end items-stretch p-4',
            inner: 'relative z-10 w-full bg-white shadow rounded-lg nice-scrollbar overflow-x-hidden md:rounded-xl h-[calc(100vh-32px)] outline-none sm:w-[540px] md:w-[600px] xl:w-[700px] 2xl:w-[800px] overflow-y-auto',
        },
        title: {
            container: 'p-4 sm:p-6 md:p-8',
            text: 'text-xl font-thin text-gray-800',
        },
        content: 'flex flex-col h-full divide-y divide-gray-100',
        section: {
            container: 'p-4 sm:p-6 md:p-8',
            title: 'mb-2 text-base md:text-lg lg:mb-4',
        },
        transition: {
            'enter-active-class': 'duration-300 transition-overshoot-sm',
            'enter-from-class': 'translate-y-[50px] sm:translate-y-0 sm:translate-x-[150px] opacity-0',
            'enter-to-class': 'translate-y-0 sm:translate-x-0 opacity-100',
            'leave-active-class': 'duration-200 ease-in',
            'leave-from-class': 'translate-y-0 sm:translate-x-0 opacity-100 blur-none',
            'leave-to-class': 'translate-y-[50px] sm:translate-y-0 sm:translate-x-[150px] opacity-0 blur-sm',
        },
    },
};

modal.right_panel_full = JSON.parse(JSON.stringify(modal.right_panel));
modal.right_panel_full.container.outer = 'z-[900] fixed top-0 right-0 bottom-0 left-0 z-50 flex justify-end items-stretch p-0';
modal.right_panel_full.container.inner = 'relative z-10 w-[calc(100%-200px)] bg-white shadow rounded-l-lg nice-scrollbar overflow-x-hidden md:rounded-l-xl h-screen outline-none overflow-y-auto';

modal.right_panel_pdf = JSON.parse(JSON.stringify(modal.right_panel));
modal.right_panel_pdf.container.inner = 'relative z-10 w-[880px] bg-white shadow rounded-lg nice-scrollbar overflow-x-hidden md:rounded-l-xl h-screen outline-none overflow-y-auto';

export default modal;
