const button = {
    style: {},
    base: 'transition-all duration-300 rounded-md relative outline-none whitespace-nowrap',
    fontSize: {
        default: 'text-xs md:text-sm',
        sm: 'text-sm',
        xs: 'text-xm',
    },
    color: {
        transparent: '',
        default: 'bg-primary text-white hover:bg-primary-light',
        default_pastel: 'bg-primary-pastel-200 text-primary hover:bg-primary-pastel-400 hover:text-primary-dark',
        default_link: 'text-primary hover:text-primary-dark',
        green: 'bg-green-600 text-white hover:bg-green-500',
        green_link: 'text-green-600 hover:text-green-500',
        gray: 'bg-neutral-100 text-gray-800 hover:text-primary hover:bg-neutral-200',
        gray_link: 'text-gray-200 hover:text-gray-300',
        disabled: 'bg-neutral-200 text-gray-800 hover:text-black',
        disabled_link: 'text-gray-200 hover:text-gray-300',
        white: 'bg-white text-black hover:bg-neutral-100',
        white_border_only: 'bg-transparent text-white hover:bg-white border border-white hover:text-primary',
        white_border: 'bg-white dark:bg-neutral-800 dark:hover:bg-neutral-600 dark:text-gray-300 text-black hover:bg-neutral-100 border border-gray-200 dark:border-gray-900',
        white_link: 'text-white hover:text-gray-100',
        link: 'text-gray-800 hover:text-black',
        delete: 'bg-red-600 hover:bg-red-800 text-white',
        delete_link: 'text-red-600 hover:text-red-800',
        red: 'bg-red-700 hover:bg-red-600 text-white',
        cancel: 'text-gray-600 hover:text-gray-800 hover:bg-neutral-100',
        warning: 'text-white bg-orange-600 hover:bg-orange-700',
        warning_gray: 'bg-neutral-100 text-gray-800 hover:text-black hover:bg-orange-600 hover:text-white',
    },
    padding: {
        '2xl': 'px-12 py-8',
        xl: 'px-12 py-6',
        lg: 'px-8 py-4',
        none: '',
        normal: 'px-6 py-2 lg:px-8 lg:py-3',
        semi: 'px-6 py-2',
        sm: 'px-6 py-1',
        sm2: 'px-6 py-2',
        sm3: 'px-4 py-2',
        xs: 'px-4 py-1',
    },
    invoice_form_action: 'flex items-center space-x-2 h-[24px] w-[24px] flex items-center justify-center text-sm text-gray-800 transition-all rounded-full hover:bg-primary hover:text-white',
};

for (const color in button.color) {
    button.style[color] = button.base + ' ' + button.color[color] + ' ' + button.padding.normal + ' ' + button.fontSize.default;
    for (const padding in button.padding) {
        button.style[`${color}_${padding}`] = button.base + ' ' + button.color[color] + ' ' + button.padding[padding] + ' ' + button.fontSize.default;
    }
}

export default button;
