import { RouteLocationNormalized } from 'vue-router';

const getBaseHomepathUrl = () => {
    const { authed } = useAuthStore();
    if (!authed!.hasPermission('app:dashboard')) {
        const { appsModules } = useModules();
        for (const possibleModule of appsModules.value) {
            const permissionKey = possibleModule.permissionKey ?? `${possibleModule.key}:access`;
            if (authed!.hasPermission(permissionKey)) {
                if (possibleModule.key == 'invoicing' && !authed!.hasPermission('invoicing:invoicing_statistics')) {
                    return `/${possibleModule.key}/contacts`;
                }
                return `/${possibleModule.key}/${possibleModule.defaultPath()}`;
            }
        }
    }

    const runtimeConfig = useRuntimeConfig();
    return `/${runtimeConfig.public.homepage}`;
};

export default (route: RouteLocationNormalized): string | null => {
    const { usableToken, authed, tenant } = useAuthStore();
    const complete = usableToken && authed && tenant;
    const loadingAuthPage = route.path.startsWith('/auth');
    const loadingTenantRegistrationPage = route.path.startsWith('/auth/register/tenant');
    const loadingImpersonationPage = route.path.startsWith('/auth/impersonate');
    const loadingTenantInaccessiblePage = route.path.startsWith('/auth/tenant');

    const getHomepageUrl = () => {
        const { query } = useRoute();
        const params = new URLSearchParams(query);
        return `${getBaseHomepathUrl()}?${params.toString()}`;
    };

    /**
     * If we load this page, we do not care about the setup.
     * This might happen after a login, or after the initial loading.
     */
    if (loadingTenantInaccessiblePage) {
        return null;
    }

    /**
     * Everything is already loaded, but the user tries to access the login, register or register tenant pages.
     * In this case, they will be redirected to the homepage.
     */
    if (complete && loadingAuthPage) {
        if (loadingTenantRegistrationPage || loadingImpersonationPage || loadingTenantInaccessiblePage) {
            return null;
        }

        return getHomepageUrl();
    }

    /**
     * User is unauthenticated but trying to impersonate.
     *
     * Redirect to login page.
     */
    if (!authed && loadingImpersonationPage) {
        return '/auth/login';
    }

    /**
     * The user has no token yet, or has a token but the user isn't retrieved.
     * In this case, they will be redirected to the login page, unless they are already on an auth page.
     */
    if ((!usableToken || !authed) && !loadingAuthPage) {
        return '/auth/login';
    }

    /**
     * The user is logged in, but has no tenant yet.
     * They need to register their tenant (company) first before using any other features.
     */
    if (authed && !tenant && !loadingTenantRegistrationPage && !route.path.startsWith('/switch-tenant')) {
        return '/auth/register/tenant';
    }

    if (authed && tenant && !tenant?.onboardingCompleted && !route.path.startsWith('/onboarding')) {
        return '/onboarding';
    }

    if (route.path == '/') {
        return getHomepageUrl();
    }

    return getSettingsRedirection(route);
};

const getSettingsRedirection = (route: RouteLocationNormalized): string | null => {
    if (!route.path.startsWith('/settings')) {
        return null;
    }

    const { authed } = useAuthStore();
    if (!authed) {
        return null;
    }

    if (authed.hasPermission('settings:access')) {
        return null;
    }

    if (authed.role === 'super-admin') {
        return null;
    }

    return getBaseHomepathUrl();
};
