import KonamiCode from 'vue3-konami-code';
// import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(KonamiCode, {
        onKonamiCodeEntered: function () {
            console.log('test');
            useEvent('app:konami-code-modal:open');
        },
    });
});
