import Tracker from '@openreplay/tracker';

export default defineNuxtPlugin(({ $config, vueApp }) => {
    // window.openreplay = new Tracker({
    //     projectKey: $config.public.openreplayKey,
    //     ingestPoint: "https://openreplay.trustup.pro/ingest",
    //     obscureTextEmails: false,
    //     obscureInputDates: false,
    //     obscureInputEmails: false,
    //     obscureInputNumbers: false,
    //     obscureTextNumbers: false,
    //     defaultInputMode: 0,
    // });
    // window.openreplay.start();
});
